import { doc, setDoc } from 'firebase/firestore';
import {
  FormControl,
  FormLabel,
  Switch,
  useToast,
  FormHelperText,
} from '@chakra-ui/react';
import { db } from '../../../../../firebase';
import { IClub } from '../../../../../types';

interface IProps {
  club: IClub;
}

export default function MugNumbers({ club }: IProps): JSX.Element {
  const toast = useToast();

  async function handleChangeHasMugNumbers(checked: boolean) {
    try {
      const clubDoc = doc(db, 'clubs', club.id);

      await setDoc(
        clubDoc,
        {
          hasMugNumbers: checked,
        },
        { merge: true },
      );

      toast({
        description: 'Mug numbers updated.',
        status: 'success',
        title: 'Success!',
      });
    } catch (error) {
      toast({
        description:
          'An error occurred updating the mug numbers. Please try again.',
        status: 'error',
        title: 'Uh Oh.',
      });
    }
  }

  return (
    <FormControl>
      <FormLabel>Mug Numbers</FormLabel>
      <Switch
        defaultChecked={club.hasMugNumbers}
        colorScheme="green"
        onChange={({ currentTarget }) =>
          handleChangeHasMugNumbers(currentTarget.checked)
        }
      />
      <FormHelperText>
        Enable mug numbers (e.g #1, #2, etc.) for club patrons.
      </FormHelperText>
    </FormControl>
  );
}
