import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
} from '@chakra-ui/react';
import { IClub } from '../../../../types';

interface IProps {
  onChange: (value: string) => void;
  selectedClub?: IClub;
}

export default function MugNumberInput({
  onChange,
  selectedClub,
}: IProps): JSX.Element | null {
  if (!selectedClub || !selectedClub.hasMugNumbers) {
    onChange('');
    return null;
  }

  return (
    <FormControl>
      <FormLabel htmlFor="phoneNumber" fontSize="xs">
        Mug Number
      </FormLabel>
      <Input
        id="mugNumber"
        type="number"
        placeholder="Mug Number"
        onChange={({ target }) => onChange(target.value)}
      />
      <FormHelperText>Optionally add a mug number.</FormHelperText>
    </FormControl>
  );
}
