import { useMemo } from 'react';
import { Button, Tooltip } from '@chakra-ui/react';
import { IBrewery, IClub, ProductPlan } from '../../../types';

interface Props {
  brewery: IBrewery;
  clubs: IClub[];
  onClick: () => void;
}

export default function ClubsActions({
  brewery,
  clubs,
  onClick,
}: Props): JSX.Element {
  const isButtonDisabled = useMemo(() => {
    const { plan } = brewery;

    return plan === ProductPlan.FREE && clubs.length >= 1;
  }, [brewery, clubs]);

  return (
    <Tooltip
      isDisabled={!isButtonDisabled}
      label="Free plans are limited to one club. Upgrade your plan to create more."
      hasArrow
      placement="left"
    >
      <Button isDisabled={isButtonDisabled} onClick={onClick} size="sm">
        Create Club
      </Button>
    </Tooltip>
  );
}
