import { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  BeersPage,
  ClubPage,
  ClubsPage,
  MessagesPage,
  MugsPage,
  NotFoundPage,
  PatronPage,
  PatronsPage,
  SettingsPage,
} from './pages';
import { FullscreenLoader, TrialExpiration } from './components';
import { BreweryProvider, useGetBrewery } from './contexts';
import { auth } from './firebase';
import { IBrewery } from './types';

function trialExpired(brewery: IBrewery): boolean {
  const { trialCreatedAt } = brewery;

  if (!trialCreatedAt) {
    return false;
  }

  const now = dayjs();

  return now.diff(trialCreatedAt.toDate(), 'day') > 14;
}

function AuthenticatedRoutes(): JSX.Element {
  const [brewery, isLoading] = useGetBrewery();
  const navigate = useNavigate();
  const isTrialExpired = useMemo(() => {
    if (!brewery) {
      return false;
    }

    return trialExpired(brewery);
  }, [brewery]);

  if (isLoading) {
    return <FullscreenLoader />;
  }

  if (!brewery && !isLoading) {
    navigate('/get-started');
  }

  if (isTrialExpired) {
    return <TrialExpiration />;
  }

  return (
    <Routes>
      <Route path="clubs" element={<ClubsPage />} />
      <Route path="clubs/:uid">
        <Route path="" element={<ClubPage />} />
        <Route path=":pane" element={<ClubPage />} />
      </Route>
      <Route path="patrons" element={<PatronsPage />} />
      <Route path="patrons/:uid" element={<PatronPage />} />
      <Route path="messages" element={<MessagesPage />} />
      <Route path="mugs" element={<MugsPage />} />
      <Route path="beers" element={<BeersPage />} />
      <Route path="settings" element={<SettingsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default function AuthenticatedApp(): JSX.Element {
  const navigate = useNavigate();
  const { currentUser } = auth;

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  return (
    <BreweryProvider userUid={currentUser?.uid}>
      <AuthenticatedRoutes />
    </BreweryProvider>
  );
}
