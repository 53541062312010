import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { IBrewery, IClub } from '../types';

interface IRequestData {
  clubId: string;
}

type TBreweryReturnData = Pick<IBrewery, 'name' | 'config'>;

type TClubReturnData = Pick<
  IClub,
  | 'benefits'
  | 'cost'
  | 'customQuestions'
  | 'membershipDurationMonths'
  | 'name'
  | 'rewards'
  | 'terms'
> & {
  endDate: string | null;
  startDate: string | null;
  registrationEndDate: string | null;
};

export interface IReturnData {
  club: TClubReturnData;
  brewery: TBreweryReturnData;
}

export const fetchClubSignupPageData = httpsCallable<IRequestData, IReturnData>(
  functions,
  'fetchClubSignupPageData',
);
