import { memo } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
} from '@chakra-ui/react';

function PatronLimitAlert(): JSX.Element {
  return (
    <Box mb={4}>
      <Alert status="warning" flexDir="column" rounded="md">
        <AlertIcon mb={2} />
        <AlertTitle fontSize="lg">Patron Limit Reached</AlertTitle>
        <AlertDescription fontSize="sm">
          The free plan has a limit of 20 patrons. Upgrade to a paid plan to
          activate more.
        </AlertDescription>
      </Alert>
    </Box>
  );
}

export default memo(PatronLimitAlert);
