import { Timestamp } from 'firebase/firestore';
import { defaultBreweryConfig } from '../../../../constants';
import { IBrewery, ProductPlan } from '../../../../types';

interface FormatBreweryDataParams {
  email: string;
  name: string;
  phoneNumber: string;
  uid: string;
  website: string;
}

export function formatBreweryData(
  params: FormatBreweryDataParams,
): Omit<IBrewery, 'id'> {
  const { email, name, phoneNumber, uid, website } = params;

  return {
    adminEmails: [email!],
    adminUid: uid,
    adminUids: [uid],
    config: {
      ...defaultBreweryConfig,
    },
    createdAt: Timestamp.now(),
    features: [],
    hasClubOverviewEmails: false,
    hasNewPatronEmails: false,
    name,
    phoneNumber,
    plan: ProductPlan.FREE,
    trialCreatedAt: Timestamp.now(),
    website,
  };
}
