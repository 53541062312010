import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { ClubStatus } from '../../types';

export async function updateClubStatus(
  clubId: string,
  status: ClubStatus,
): Promise<void> {
  if (!clubId || !status) {
    return;
  }

  const clubRef = doc(db, `clubs/${clubId}`);

  await updateDoc(clubRef, {
    status,
  });
}
