import { useState } from 'react';
import { addDoc, collection, doc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Divider,
  Heading,
} from '@chakra-ui/react';
import { captureException } from '@sentry/react';
import ClubSelect from './ClubSelect';
import MugNumberInput from './MugNumberInput';
import { Drawer } from '../../../../components';
import { db } from '../../../../firebase';
import { IClub, IPatron } from '../../../../types';

interface IProps {
  breweryId?: string;
  clubs?: IClub[];
  isOpen: boolean;
  onClose: () => void;
}

type TErrors = {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  phoneNumber: boolean;
  birthdayMonth: boolean;
  clubId: boolean;
};

export default function AddPatronFormDrawer({
  breweryId,
  clubs,
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [birthdayMonth, setBirthdayMonth] = useState<string>();
  const [mugNumber, setMugNumber] = useState<string>('');
  const [shirtSize, setShirtSize] = useState<string>();
  const [clubId, setClubId] = useState<string>('');
  const [errors, setErrors] = useState<TErrors | undefined>();
  const toast = useToast();
  const navigate = useNavigate();

  function handleOnClose() {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setBirthdayMonth('');
    setMugNumber('');
    setShirtSize('');
    setClubId('');
    setErrors(undefined);
    onClose();
  }

  function validateForm() {
    const errors: TErrors = {
      firstName: !Boolean(firstName),
      lastName: !Boolean(lastName),
      email: !Boolean(email),
      phoneNumber: !Boolean(phoneNumber),
      birthdayMonth: !Boolean(birthdayMonth),
      clubId: !Boolean(clubId),
    };

    setErrors(errors);

    return Object.values(errors).some((error) => error);
  }

  async function handleSubmit() {
    if (!breweryId) {
      return;
    }

    const hasErrors = validateForm();

    if (hasErrors) {
      return;
    }

    try {
      const club = clubs?.find(({ id }) => id === clubId);
      const patronsCollection = collection(db, 'patrons');
      const breweryRef = doc(db, 'breweries', breweryId);
      const patronData: Omit<IPatron, 'id'> = {
        firstName,
        lastName,
        email,
        phoneNumber: phoneNumber!,
        birthdayMonth,
        shirtSize: shirtSize || null,
        mugNumber: parseInt(mugNumber) || null,
        notes: {
          breweryId: null,
        },
        breweryRef,
        createdAt: Timestamp.now(),
        lastUpdatedAt: Timestamp.now(),
        clubs: [clubId],
      };
      const patronDoc = await addDoc(patronsCollection, patronData);

      const membershipsCollection = collection(db, 'memberships');
      const { endDate } = club!;
      const membershipData = {
        activatedAt: null,
        breweryId,
        clubId,
        createdAt: Timestamp.now(),
        expiresAt: endDate ? endDate : null,
        isActive: false,
        patronId: patronDoc.id,
      };

      await addDoc(membershipsCollection, membershipData);

      toast({
        description: 'Patron added',
        status: 'success',
        title: 'Success!',
      });

      navigate(`/auth/patrons/${patronDoc.id}`);
    } catch (error) {
      toast({
        description:
          'An error occurred while adding the patron. Please try again later.',
        status: 'error',
        title: 'Uh Oh.',
      });
      captureException(error);
    }
  }

  return (
    <Drawer
      title="Add Patron"
      buttonText="Submit"
      isOpen={isOpen}
      onClose={handleOnClose}
      onSubmit={handleSubmit}
      size="lg"
    >
      <VStack spacing={3}>
        <Heading size="sm" alignSelf="flex-start">
          Club Details
        </Heading>

        <ClubSelect
          clubs={clubs}
          onChange={setClubId}
          hasError={errors?.clubId}
        />

        <MugNumberInput
          onChange={setMugNumber}
          selectedClub={clubs?.find((club) => club.id === clubId)}
        />

        <Divider my={2} />

        <Heading size="sm" alignSelf="flex-start">
          Patron Details
        </Heading>

        <FormControl isRequired isInvalid={errors?.firstName}>
          <FormLabel htmlFor="firstName" fontSize="xs">
            First Name
          </FormLabel>
          <Input
            id="firstName"
            type="name"
            placeholder="First Name"
            onChange={({ target }) => setFirstName(target.value)}
          />
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors?.lastName}>
          <FormLabel htmlFor="lastName" fontSize="xs">
            Last Name
          </FormLabel>
          <Input
            id="lastName"
            type="name"
            placeholder="Last Name"
            onChange={({ target }) => setLastName(target.value)}
          />
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors?.email}>
          <FormLabel htmlFor="email" fontSize="xs">
            Email
          </FormLabel>
          <Input
            id="email"
            type="email"
            placeholder="Email"
            onChange={({ target }) => setEmail(target.value)}
          />
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors?.phoneNumber}>
          <FormLabel htmlFor="phoneNumber" fontSize="xs">
            Phone Number
          </FormLabel>
          <Input
            id="phoneNumber"
            placeholder="Phone Number"
            onChange={({ target }) => setPhoneNumber(target.value)}
          />
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={errors?.birthdayMonth}>
          <FormLabel fontSize="xs">Birthday Month</FormLabel>
          <Select
            placeholder="Select Month"
            onChange={({ currentTarget }) =>
              setBirthdayMonth(currentTarget.value)
            }
            borderColor="green.200"
          >
            <option value="january">January</option>
            <option value="february">February</option>
            <option value="march">March</option>
            <option value="april">April</option>
            <option value="may">May</option>
            <option value="june">June</option>
            <option value="july">July</option>
            <option value="august">August</option>
            <option value="september">September</option>
            <option value="october">October</option>
            <option value="november">November</option>
            <option value="december">December</option>
          </Select>
          <FormErrorMessage>This field is required.</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel fontSize="xs">T-Shirt Size</FormLabel>
          <Select
            placeholder="Select Size"
            onChange={({ currentTarget }) => setShirtSize(currentTarget.value)}
            borderColor="green.200"
          >
            <option value="x-small">X-Small</option>
            <option value="small">Small</option>
            <option value="medium">Medium</option>
            <option value="large">Large</option>
            <option value="x-large">X-Large</option>
            <option value="xx-large">XX-Large</option>
            <option value="xxx-large">XXX-Large</option>
          </Select>
          <FormHelperText>Optionally add a t-shirt size.</FormHelperText>
        </FormControl>
      </VStack>
    </Drawer>
  );
}
