import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FlowStepper } from '..';
import AccountSetup from './AccountSetup';
import BreweryInfo from './BreweryInfo';
import { auth, db } from '../../../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

type TStep = 0 | 1;

export default function FormStepper(): JSX.Element {
  const [activeStep, setActiveStep] = useState<TStep>(0);
  const navigate = useNavigate();

  useEffect(() => {
    async function checkForUserAndBrewery() {
      const { currentUser } = auth;

      if (!currentUser) {
        return;
      }

      const { uid } = currentUser;
      const breweryCollection = collection(db, 'breweries');
      const q = query(
        breweryCollection,
        where('adminUids', 'array-contains', uid),
      );
      const querySnapshot = await getDocs(q);
      const userHasBrewery = Boolean(querySnapshot.size);

      if (currentUser && !userHasBrewery) {
        setActiveStep(1);
      }

      if (currentUser && userHasBrewery) {
        navigate('/auth/clubs');
      }
    }

    checkForUserAndBrewery();
  }, [navigate]);

  function handleNext() {
    setActiveStep(1);
  }

  return (
    <Box>
      <Box mb={8}>
        <FlowStepper activeStep={activeStep} />
      </Box>

      {activeStep === 0 && <AccountSetup onSubmit={handleNext} />}
      {activeStep === 1 && <BreweryInfo />}
    </Box>
  );
}
