import { Box, Button, Heading, Link, Text } from '@chakra-ui/react';
import { ExternalLinkIcon } from '../../../assets';

export default function SubscriptionTab(): JSX.Element {
  return (
    <Box maxWidth={580}>
      <Heading as="h2" fontSize="lg" mb={4}>
        Manage Subscription
      </Heading>

      <Text mb={4}>
        Use the button below to manage your subscription via Stripe. You will be
        prompted to enter the email associated with your MugClub.beer account.
      </Text>

      <Button
        as={Link}
        leftIcon={<ExternalLinkIcon />}
        href="https://billing.stripe.com/p/login/6oE01KcVGd7c3le288"
        isExternal
        mb={4}
      >
        Open Stripe Portal
      </Button>

      <Text fontSize="sm" textColor="gray.500">
        If you have any billing-related questions, please reach out to{' '}
        <b>chandler@mugclub.beer</b>
      </Text>
    </Box>
  );
}
