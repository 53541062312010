import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export async function updatePatronNotes(
  patronId: string,
  {
    breweryId,
    notes,
  }: {
    breweryId: string;
    notes?: string;
  },
): Promise<void> {
  if (!patronId || !breweryId) {
    return;
  }

  const patronRef = doc(db, `patrons/${patronId}`);

  await updateDoc(patronRef, {
    [`notes.${breweryId}`]: notes || null,
  });
}
