import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { useGetBrewery } from '../../../../contexts';
import { useFetchPatrons } from '../../../../hooks';
import { IClub, ProductPlan } from '../../../../types';

const FREE_PLAN_PATRON_LIMIT = 20;

export function setNewExpirationDate(club: IClub): Date {
  const { membershipDurationMonths } = club;

  return dayjs().add(membershipDurationMonths!, 'month').toDate();
}

export function timestampToDateInput(date: Timestamp): string {
  return dayjs(date.toDate()).format('YYYY-MM-DD');
}

export function usePatronLimitReached(): boolean {
  const [brewery] = useGetBrewery();
  const [patrons] = useFetchPatrons(brewery.id);
  const { plan } = brewery;

  if (plan === ProductPlan.FREE && patrons.length > FREE_PLAN_PATRON_LIMIT) {
    return true;
  }

  return false;
}
