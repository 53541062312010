import { ComponentStyleConfig } from '@chakra-ui/react';

const Input: ComponentStyleConfig = {
  baseStyle: {
    field: {
      bg: 'white',
      background: 'white',
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: 'green.200',
        _hover: {
          borderColor: 'green.500',
        },
        _focus: {
          borderColor: 'green.500',
          boxShadow: 'none',
        },
      },
    },
  },
};

export default Input;
