import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useClubCreatorContext } from '../Context';
import { Calendar, Repeat, Users } from '../../../assets';

export default function ClubDetailsList(): JSX.Element {
  const { clubType, formValues } = useClubCreatorContext();
  const {
    name,
    cost,
    renewalCost,
    capacity,
    startDate,
    endDate,
    registrationEndDate,
    membershipDurationMonths,
  } = formValues;

  return (
    <>
      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Basics
      </Text>

      <FormControl mb={3}>
        <FormLabel htmlFor="name">Club Name</FormLabel>
        <Input
          id="name"
          type="name"
          placeholder="Club Name"
          value={name}
          readOnly
        />
      </FormControl>

      <Box display="flex" w="100%" columnGap={4} mb={3}>
        <FormControl>
          <FormLabel htmlFor="cost">Membership Cost</FormLabel>

          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.500"
              children="$"
            />
            <Input
              id="cost"
              type="number"
              placeholder="Cost"
              value={cost}
              readOnly
            />
          </InputGroup>
        </FormControl>

        {renewalCost && (
          <FormControl>
            <FormLabel htmlFor="renewalCost">Renewal Cost</FormLabel>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                children="$"
              />
              <Input
                id="renewalCost"
                type="number"
                placeholder="Renewal Cost"
                value={renewalCost}
                readOnly
              />
            </InputGroup>
          </FormControl>
        )}

        {capacity && (
          <FormControl>
            <FormLabel htmlFor="capacity">Membership Capacity</FormLabel>

            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                color="gray.500"
                children={<Icon as={Users} />}
              />
              <Input
                id="capacity"
                type="number"
                placeholder="Capacity"
                value={capacity}
                readOnly
              />
            </InputGroup>
          </FormControl>
        )}
      </Box>

      <Box my={6}>
        <hr />
      </Box>

      {clubType && (
        <>
          <Text fontSize="lg" fontWeight="semibold" mb={4}>
            Structure
          </Text>

          <Box
            alignItems="center"
            display="inline-flex"
            justifyContent="center"
            border="1px solid"
            borderColor="gray.300"
            px={4}
            py={1}
            rounded="md"
            gap={2}
          >
            {clubType === 'fixed' ? <Calendar /> : <Repeat />}

            <Text textTransform="capitalize" fontWeight="medium">
              {clubType}
            </Text>
          </Box>

          <Box my={6}>
            <hr />
          </Box>
        </>
      )}

      <Text fontSize="lg" fontWeight="semibold" mb={4}>
        Cadence
      </Text>

      <Box display="flex" w="100%" columnGap={4}>
        {startDate && (
          <FormControl>
            <FormLabel htmlFor="startDate">Start Date</FormLabel>
            <Input id="startDate" type="date" value={startDate} readOnly />
          </FormControl>
        )}

        {endDate && (
          <FormControl>
            <FormLabel htmlFor="endDate">End Date</FormLabel>
            <Input id="endDate" type="date" value={endDate} readOnly />
          </FormControl>
        )}

        {registrationEndDate && (
          <FormControl>
            <FormLabel htmlFor="registrationDeadline">
              Registration Deadline
            </FormLabel>
            <Input
              id="registrationDeadline"
              type="date"
              value={registrationEndDate}
              readOnly
            />
          </FormControl>
        )}

        {membershipDurationMonths && (
          <FormControl>
            <FormLabel htmlFor="membershipDurationMonths">
              Membership Duration in Months
            </FormLabel>

            <Input
              id="membershipDurationMonths"
              value={membershipDurationMonths}
              type="number"
              readOnly
            />
          </FormControl>
        )}
      </Box>
    </>
  );
}
