import { IClub, IPatron } from '../../types';

interface IMug {
  number: number;
  patron?: IPatron;
}

export function formatMugs({
  club,
  patrons,
  status,
}: {
  club?: IClub;
  patrons?: IPatron[];
  status: string;
}): IMug[] | null {
  if (!club || !patrons?.length) {
    return null;
  }

  let capacity;

  if (club.capacity) {
    capacity = club.capacity;
  } else {
    capacity = patrons.length;
  }

  const mugs: IMug[] = [];

  for (let i = 1; i <= capacity!; i++) {
    const patron = patrons?.find((patron) => {
      if (!patron.mugNumber) {
        return false;
      }

      return patron.mugNumber === i;
    });

    if (status === 'available' && patron) {
      continue;
    } else {
      mugs.push({
        number: i,
        patron,
      });
    }
  }

  return mugs;
}
