import { useEffect, useState } from 'react';
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
} from '@chakra-ui/react';
import CompletedClubAlert from './CompletedClubAlert';
import ExpirationDateInput from './ExpirationDateInput';
import PatronLimitAlert from './PatronLimitAlert';
import StatusSwitch from './StatusSwitch';
import {
  setNewExpirationDate,
  timestampToDateInput,
  usePatronLimitReached,
} from './utils';
import { usePatronContext } from '../../Context';
import { useFetchPatronMembershipByClub } from '../../../../hooks';
import { ClubStatus, IClub, IMembership, IPatron } from '../../../../types';
import { db } from '../../../../firebase';

interface IProps {
  club: IClub;
  isOpen: boolean;
  onClose: () => void;
}

export default function MembershipManager({
  club,
  isOpen,
  onClose,
}: IProps): JSX.Element {
  const patronLimitReached = usePatronLimitReached();
  const { patron } = usePatronContext();
  const [membershipExpirationDate, setMembershipExpirationDate] = useState<
    string | undefined
  >(undefined);
  const [isMembershipActive, setIsMembershipActive] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [membership] = useFetchPatronMembershipByClub(patron?.id, club.id);
  const toast = useToast();
  const { endDate, name, status } = club;
  const clubHasEndDate = Boolean(endDate);
  const isClubComplete = status === ClubStatus.COMPLETE;

  useEffect(() => {
    if (!membership) {
      return;
    }

    const { expiresAt, isActive } = membership;

    if (expiresAt) {
      const date = timestampToDateInput(expiresAt);
      setMembershipExpirationDate(date);
    } else {
      const { membershipDurationMonths } = club;
      const formattedDate = dayjs()
        .add(membershipDurationMonths!, 'month')
        .format('YYYY-MM-DD');
      setMembershipExpirationDate(formattedDate);
    }

    setIsMembershipActive(isActive);
  }, [club, membership]);

  async function handleOnSubmit(): Promise<void> {
    if (membership?.expiresAt && !membershipExpirationDate) {
      setHasError(true);
      return;
    }

    setIsFormSubmitting(true);

    try {
      const patronRef = doc(db, `patrons/${patron?.id}`);
      const membershipRef = doc(db, `memberships/${membership?.id}`);

      const expirationDate = membershipExpirationDate
        ? dayjs(membershipExpirationDate).toDate()
        : setNewExpirationDate(club);

      const membershipData: Pick<IMembership, 'expiresAt' | 'isActive'> = {
        expiresAt: Timestamp.fromDate(expirationDate),
        isActive: isMembershipActive,
      };

      await updateDoc(membershipRef, membershipData);

      const patronData: Pick<IPatron, 'lastUpdatedAt'> = {
        lastUpdatedAt: Timestamp.now(),
      };

      await updateDoc(patronRef, patronData);

      toast({
        description: "Patron's membership updated.",
        status: 'success',
        title: 'Success!',
      });

      onClose();
    } catch (error) {
      toast({
        description:
          "An error occurred updating the patron's membership. Please try again.",
        status: 'error',
        title: 'Uh Oh.',
      });
    } finally {
      setIsFormSubmitting(false);
    }
  }

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Update Membership for {name}</DrawerHeader>

        <DrawerBody>
          {patronLimitReached && <PatronLimitAlert />}
          {isClubComplete && <CompletedClubAlert />}

          <StatusSwitch
            isChecked={isMembershipActive}
            isDisabled={patronLimitReached || isClubComplete}
            onChange={setIsMembershipActive}
          />

          <ExpirationDateInput
            clubHasEndDate={clubHasEndDate}
            hasError={hasError}
            isDisabled={patronLimitReached || clubHasEndDate || isClubComplete}
            onChange={setMembershipExpirationDate}
            value={membershipExpirationDate}
          />
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleOnSubmit}
            isLoading={isFormSubmitting}
            isDisabled={patronLimitReached || isClubComplete}
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
