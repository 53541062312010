import { addDoc, collection, doc, Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import { db } from '../../../firebase';
import { FormValues } from '../Context/utils';
import { ClubStatus, IBrewery, IClub } from '../../../types';

export async function createClub(
  brewery: IBrewery,
  formValues: FormValues,
): Promise<any> {
  const clubsCollection = collection(db, 'clubs');
  const clubData = buildClubData(brewery, formValues);

  const { id } = await addDoc(clubsCollection, clubData);

  return id;
}

export function buildClubData(
  brewery: IBrewery,
  formValues: FormValues,
): Omit<IClub, 'id'> {
  const { id: breweryId, adminUid } = brewery;

  const breweryRef = doc(db, 'breweries', breweryId);

  return {
    benefits: formValues.benefits,
    breweryId,
    breweryRef,
    capacity: formValues.capacity ? parseInt(formValues.capacity) : null,
    cost: formValues.cost ? parseInt(formValues.cost) : null,
    createdAt: Timestamp.now(),
    createdBy: adminUid,
    customQuestions: formValues.customQuestions || null,
    endDate: formateDate(formValues.endDate),
    hasPatronApp: false,
    hasMugNumbers: false,
    hasRenewalReminders: false,
    membershipDurationMonths: formValues.membershipDurationMonths
      ? parseInt(formValues.membershipDurationMonths as string)
      : null,
    name: formValues.name,
    renewalCost: formValues.renewalCost
      ? parseInt(formValues.renewalCost)
      : null,
    rewards: formValues.rewards,
    startDate: formateDate(formValues.startDate),
    status: ClubStatus.DRAFT,
    terms: formValues.terms,
  };
}

export function formateDate(date: string | undefined): Timestamp | null {
  if (!date) {
    return null;
  }

  const now = dayjs();

  return Timestamp.fromDate(
    dayjs(date).set('hour', now.hour()).set('minute', now.minute()).toDate(),
  );
}
