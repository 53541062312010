import { Heading, Text } from '@chakra-ui/react';
import { FormStepper } from './components';
import { PublicLayout } from '../../components';

export default function GetStarted(): JSX.Element {
  return (
    <PublicLayout>
      <Heading as="h1" mb={1}>
        Start Your 30-Day Free Trial
      </Heading>
      <Text mb={8} color="gray.500">
        Get started with MugClub.beer in minutes. No credit card required.
      </Text>

      <FormStepper />
    </PublicLayout>
  );
}
