import { useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import EmptyState from './EmptyState';
import LoadingState from './LoadingState';
import { formatMugs } from './utils';
import { useFetchPatronsByClub } from '../../hooks';
import { IClub } from '../../types';

interface IProps {
  club?: IClub;
  isGridView: boolean;
  status: string;
}

export default function MugsTable({
  club,
  isGridView,
  status,
}: IProps): JSX.Element {
  const [patrons, isLoading] = useFetchPatronsByClub(club?.id);

  const mugs = useMemo(
    () => formatMugs({ club, patrons, status }),
    [club, patrons, status],
  );

  if (isLoading) return <LoadingState />;

  if (!club?.id) return <EmptyState />;

  if (mugs && mugs.length === 0)
    return (
      <EmptyState
        title="No results found"
        description="Try changing your filters"
      />
    );

  return (
    <Box>
      {isGridView && (
        <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap={4}>
          {mugs?.map((mug) => (
            <Box
              key={mug.number}
              border="1px"
              borderColor="gray.200"
              textAlign="center"
              p={4}
              rounded="md"
              bg={mug?.patron ? 'gray.50' : 'green.100'}
            >
              <Text fontSize="2xl" fontWeight="medium" mb={1}>
                {mug.number}
              </Text>
              <Text>
                {mug?.patron?.firstName} {mug?.patron?.lastName}
              </Text>
              {!mug?.patron && (
                <Text color="green.500" fontWeight="medium">
                  Available
                </Text>
              )}
            </Box>
          ))}
        </Box>
      )}

      {!isGridView && (
        <Box display="flex" flexDirection="column" gap={2}>
          {mugs?.map((mug) => (
            <Box
              key={mug.number}
              bg={mug?.patron ? 'gray.50' : 'green.100'}
              border="1px"
              borderColor="gray.200"
              display="flex"
              p={4}
              rounded="md"
              textAlign="center"
              alignItems="center"
            >
              <Text fontSize="lg" fontWeight="medium" mr={2}>
                {mug.number}
              </Text>
              <Text>
                {mug?.patron?.firstName} {mug?.patron?.lastName}
              </Text>
              {!mug?.patron && (
                <Text color="green.500" fontWeight="medium">
                  Available
                </Text>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
