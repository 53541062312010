import { useMemo } from 'react';
import { ClubCreatorProvider, useClubCreatorContext } from './Context';
import ClubBasics from './ClubBasics';
import ClubBenefits from './ClubBenefits';
import ClubCadence from './ClubCadence';
import ClubRewards from './ClubRewards';
import ClubStructure from './ClubStructure';
import ClubTerms from './ClubTerms';
import CustomQuestions from './CustomQuestions';
import Review from './Review';
import Step from './Step';
import { Drawer } from '../';

function getStepComponent(step: number): () => JSX.Element {
  switch (step) {
    case 1:
      return ClubBasics;
    case 2:
      return ClubStructure;
    case 3:
      return ClubCadence;
    case 4:
      return ClubBenefits;
    case 5:
      return ClubRewards;
    case 6:
      return CustomQuestions;
    case 7:
      return ClubTerms;
    case 8:
      return Review;
    default:
      return ClubBasics;
  }
}

export function ClubCreator(): JSX.Element {
  const { step } = useClubCreatorContext();
  const StepComponent = useMemo(() => getStepComponent(step), [step]);

  return (
    <Step>
      <StepComponent />
    </Step>
  );
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function ClubCreatorDrawer({
  isOpen,
  onClose,
}: Props): JSX.Element {
  return (
    <Drawer
      closeOnEsc={false}
      isOpen={isOpen}
      onClose={onClose}
      title="Create Mug Club"
      showFooter={false}
      size="full"
    >
      <ClubCreatorProvider>
        <ClubCreator />
      </ClubCreatorProvider>
    </Drawer>
  );
}
