import { DocumentReference, Timestamp } from 'firebase/firestore';

export interface IBeer {
  abv: string;
  description?: string;
  id: string;
  isActive: boolean;
  name: string;
  style: string;
}

export interface IBrewery {
  id: string;
  adminEmails: string[];
  adminUid: string;
  adminUids: string[];
  config?: IBreweryConfig;
  createdAt: Timestamp;
  features?: Features[];
  hasClubOverviewEmails: boolean;
  hasNewPatronEmails: boolean;
  name: string;
  phoneNumber: string;
  plan: ProductPlan;
  slug?: string;
  trialCreatedAt?: Timestamp | null;
  website: string;
}

export enum ProductPlan {
  FREE = 'free',
  STARTER = 'starter',
  CORE = 'core',
  COMPLETE = 'complete',
}

export enum BreweryConfigOptions {
  birthdayMessageEnabled = 'birthdayMessageEnabled',
  clubSignUpConfirmation = 'clubSignUpConfirmation',
  clubSignUpInstructions = 'clubSignUpInstructions',
  patronEarnedRewardEmailEnabled = 'patronEarnedRewardEmailEnabled',
  patronSignedUpForClubEmailEnabled = 'patronSignedUpForClubEmailEnabled',
}

export interface IBreweryConfig {
  [BreweryConfigOptions.birthdayMessageEnabled]: boolean;
  [BreweryConfigOptions.clubSignUpConfirmation]: string;
  [BreweryConfigOptions.clubSignUpInstructions]: string;
  [BreweryConfigOptions.patronEarnedRewardEmailEnabled]: boolean;
  [BreweryConfigOptions.patronSignedUpForClubEmailEnabled]: boolean;
}

export enum Features {
  BENEFITS_TRACKING = 'benefits_tracking',
}

export enum ClubStatus {
  ACTIVE = 'active',
  COMPLETE = 'complete',
  DRAFT = 'draft',
}

export interface IClub {
  id: string;
  benefits: string[];
  breweryId: string;
  breweryRef: DocumentReference;
  capacity?: number | null;
  cost?: number | null;
  createdAt: Timestamp;
  createdBy: string;
  customQuestions?: string | null;
  endDate: Timestamp | null;
  hasMugNumbers?: boolean;
  hasPatronApp?: boolean;
  hasRenewalReminders?: boolean;
  isActive?: boolean;
  membershipDurationMonths?: number | null;
  name: string;
  notes?: string;
  registrationEndDate?: Timestamp | null;
  renewalCost?: number | null;
  rewards: IClubReward[];
  startDate: Timestamp | null;
  status: ClubStatus;
  terms?: string;
}

export interface IClubReward {
  description: string;
  id: string;
  threshold: number;
}

export interface IPatron {
  id: string;
  birthdayMonth?: string | null;
  breweryRef: DocumentReference;
  clubs: string[];
  createdAt: Timestamp;
  email: string;
  firstName: string;
  isActive?: boolean;
  lastName: string;
  lastUpdatedAt?: Timestamp | null;
  membershipActivatedAt?: string | null;
  membershipExpiresAt?: string | null;
  memberSince?: number | null;
  mugNumber?: number | null;
  notes?: {
    [key: string]: string | null;
  };
  phoneNumber: string;
  shirtSize?: string | null;
}

export interface IPatronReward {
  id: string;
  breweryId?: string;
  breweryRef: DocumentReference;
  clubId?: string;
  clubRef: DocumentReference;
  createdAt: Timestamp;
  description: string;
  patronId?: string;
  patronRef: DocumentReference;
  redeemed: boolean;
  redeemedAt: Timestamp | null;
  rewardId?: string;
  threshold: number;
}

export interface IMembership {
  id: string;
  activatedAt: Timestamp | null;
  breweryId: string;
  clubId: string;
  createdAt: Timestamp | null;
  expiresAt: Timestamp | null;
  isActive: boolean;
  patronId: string;
}

export interface IRecord {
  id: string;
  bartender: {
    name: string;
    ref: DocumentReference;
  };
  breweryId?: string;
  clubId?: string;
  clubRef: DocumentReference;
  createdAt: Timestamp;
  patronId?: string;
  patronRef: DocumentReference;
}

export interface IBartender {
  id: string;
  archivedAt: Timestamp | null;
  breweryRef: DocumentReference;
  createdAt: Timestamp;
  isActive: boolean;
  name: string;
}

export interface ITrackedBenefit {
  id: string;
  benefit: string;
  breweryId: string;
  clubId: string;
  createdAt: Timestamp;
  createdBy: string;
  patronId: string;
}
