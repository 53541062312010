import { Box, Link, Text } from '@chakra-ui/react';
import { Phone, Mail } from '../../assets';

export default function TrialExpiration(): JSX.Element {
  return (
    <Box
      alignItems="center"
      display="flex"
      h="100vh"
      justifyContent="center"
      bg="gray.50"
    >
      <Box
        bg="white"
        border="2px solid"
        borderColor="green.200"
        p={12}
        rounded="md"
        shadow="md"
        textAlign="center"
      >
        <Text fontSize="8xl" textAlign="center">
          🍺
        </Text>

        <Text fontSize="2xl" fontWeight="medium" mb={4} textAlign="center">
          Free Trial Concluded
        </Text>

        <Box
          color="gray.600"
          margin="auto"
          maxWidth={512}
          textAlign="center"
          mb={8}
        >
          <Text mb={2}>Thank you for trialing MugClub.beer!</Text>

          <Text>
            We hope you found our product useful. Please reach out to us if you
            would like to setup a monthly subscription.
          </Text>
        </Box>

        <Box
          alignItems="center"
          border="2px solid"
          borderColor="gray.200"
          display="flex"
          flexDir="column"
          gap={2}
          mb={2}
          p={4}
          rounded="md"
          shadow="sm"
        >
          <Box
            alignItems="center"
            display="flex"
            fontSize="lg"
            fontWeight="medium"
            gap={2}
          >
            <Phone />
            <Text>Call</Text>
          </Box>
          <Text fontSize="sm">
            If you'd like to speak directly to a human, setup a call{' '}
            <Link
              href="https://calendly.com/mugclub/product-overview"
              target="_blank"
              rel="noreferrer"
              fontWeight="bold"
            >
              here
            </Link>
            .
          </Text>
        </Box>

        <Box
          alignItems="center"
          border="2px solid"
          borderColor="gray.200"
          display="flex"
          flexDir="column"
          gap={2}
          p={4}
          rounded="md"
          shadow="sm"
        >
          <Box
            alignItems="center"
            display="flex"
            fontSize="lg"
            fontWeight="medium"
            gap={2}
          >
            <Mail />
            <Text>Email</Text>
          </Box>
          <Text fontSize="sm">
            Contact us via email at{' '}
            <Link href="mailto:help@mugclub.beer" fontWeight="bold">
              help@mugclub.beer
            </Link>
            .
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
