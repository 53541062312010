import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../firebase';
import { ArrowRightIcon } from '../../../../assets';

interface IProps {
  onSubmit: () => void;
}

export default function AccountSetup({ onSubmit }: IProps): JSX.Element {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const toast = useToast();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setHasError(false);

    if (password.length < 6) {
      setHasError(true);
    }

    setIsLoading(true);

    try {
      await createUserWithEmailAndPassword(auth, email, password);

      onSubmit();
    } catch (error) {
      setHasError(true);

      toast({
        isClosable: true,
        description: 'An error occurred while creating your account.',
        position: 'top-right',
        status: 'error',
        title: 'Uh Oh.',
        variant: 'left-accent',
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <FormControl isRequired mb={4}>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          placeholder="Email"
          type="email"
          onChange={({ currentTarget }) => setEmail(currentTarget.value)}
        />
      </FormControl>

      <FormControl isRequired mb={6} isInvalid={hasError}>
        <FormLabel htmlFor="password">Password</FormLabel>
        <Input
          placeholder="Password"
          type="password"
          onChange={({ currentTarget }) => setPassword(currentTarget.value)}
        />
        <FormErrorMessage>
          Password must be at least 6 characters long.
        </FormErrorMessage>
      </FormControl>

      <Button
        isLoading={isLoading}
        rightIcon={<ArrowRightIcon />}
        w="100%"
        type="submit"
      >
        Next
      </Button>

      <Text color="gray.500" fontSize="sm" mt={2}>
        By clicking "Next" you agree to MugClub.beer's{' '}
        <Link target="_blank" href="https://mugclub.beer/terms-of-service">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link target="_blank" href="https://mugclub.beer/privacy-policy">
          Privacy Policy
        </Link>
        .
      </Text>
    </Box>
  );
}
