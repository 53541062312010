import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { Drawer } from '../';
import { IClub, IPatron } from '../../types';

interface IProps {
  club?: IClub;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: IPatronFormValues) => Promise<void>;
  patron?: IPatron;
}

export interface IPatronFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  birthdayMonth?: string;
  mugNumber?: string;
  shirtSize?: string;
}

function getInitialValues(patron?: IPatron): IPatronFormValues {
  if (!patron) {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      birthdayMonth: '',
      mugNumber: '',
      shirtSize: '',
    };
  }

  return {
    firstName: patron.firstName,
    lastName: patron.lastName,
    email: patron.email,
    phoneNumber: patron.phoneNumber,
    birthdayMonth: patron.birthdayMonth || '',
    mugNumber: patron.mugNumber ? String(patron.mugNumber) : '',
    shirtSize: patron.shirtSize || '',
  };
}

export default function PatronForm({
  club,
  isOpen,
  onClose,
  onSubmit,
  patron,
}: IProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handleSubmit(values: IPatronFormValues): Promise<void> {
    setIsLoading(true);

    try {
      await onSubmit(values);
      onClose();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Drawer
      title={`${patron ? 'Edit' : 'Add New'} Patron`}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
      showFooter={false}
    >
      <Formik initialValues={getInitialValues(patron)} onSubmit={handleSubmit}>
        {(props) => (
          <Box h="-webkit-fill-available">
            <form
              onSubmit={props.handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 'inherit',
              }}
            >
              <VStack spacing={3} mb={16}>
                {club?.hasMugNumbers && (
                  <>
                    <Heading alignSelf="flex-start" size="sm">
                      Club Details
                    </Heading>

                    <FormControl>
                      <FormLabel htmlFor="mugNumber" fontSize="sm">
                        Mug Number
                      </FormLabel>
                      <Input
                        name="mugNumber"
                        type="number"
                        placeholder="Mug Number"
                        onChange={props.handleChange}
                        value={props.values.mugNumber}
                      />
                      <FormHelperText>
                        Optionally add a mug number.
                      </FormHelperText>
                    </FormControl>

                    <Divider my={2} />
                  </>
                )}

                <Heading alignSelf="flex-start" size="sm">
                  Patron Details
                </Heading>

                <FormControl isRequired>
                  <FormLabel htmlFor="firstName" fontSize="sm">
                    First Name
                  </FormLabel>
                  <Input
                    name="firstName"
                    type="name"
                    placeholder="First Name"
                    onChange={props.handleChange}
                    value={props.values.firstName}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="lastName" fontSize="sm">
                    Last Name
                  </FormLabel>
                  <Input
                    name="lastName"
                    type="name"
                    placeholder="Last Name"
                    onChange={props.handleChange}
                    value={props.values.lastName}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="email" fontSize="sm">
                    Email
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={props.handleChange}
                    value={props.values.email}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="phoneNumber" fontSize="sm">
                    Phone Number
                  </FormLabel>
                  <Input
                    name="phoneNumber"
                    placeholder="Phone Number"
                    onChange={props.handleChange}
                    value={props.values.phoneNumber}
                  />
                </FormControl>

                <FormControl isRequired>
                  <FormLabel htmlFor="birthdayMonth" fontSize="sm">
                    Birthday Month
                  </FormLabel>
                  <Select
                    borderColor="green.200"
                    name="birthdayMonth"
                    placeholder="Select Month"
                    onChange={props.handleChange}
                    value={props.values.birthdayMonth}
                  >
                    <option value="january">January</option>
                    <option value="february">February</option>
                    <option value="march">March</option>
                    <option value="april">April</option>
                    <option value="may">May</option>
                    <option value="june">June</option>
                    <option value="july">July</option>
                    <option value="august">August</option>
                    <option value="september">September</option>
                    <option value="october">October</option>
                    <option value="november">November</option>
                    <option value="december">December</option>
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="shirtSize" fontSize="sm">
                    T-Shirt Size
                  </FormLabel>
                  <Select
                    borderColor="green.200"
                    name="shirtSize"
                    placeholder="Select Size"
                    onChange={props.handleChange}
                    value={props.values.shirtSize}
                  >
                    <option value="x-small">X-Small</option>
                    <option value="small">Small</option>
                    <option value="medium">Medium</option>
                    <option value="large">Large</option>
                    <option value="x-large">X-Large</option>
                    <option value="xx-large">XX-Large</option>
                    <option value="xxx-large">XXX-Large</option>
                  </Select>
                  <FormHelperText>
                    Optionally add the size of this patron's t-shirt
                  </FormHelperText>
                </FormControl>
              </VStack>

              <Box
                display="flex"
                justifyContent="flex-end"
                gap={2}
                mt="auto"
                pb={4}
              >
                <Button variant="outline" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  background="green.500"
                  color="white"
                  disabled={props.isSubmitting}
                  isLoading={isLoading}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Box>
        )}
      </Formik>
    </Drawer>
  );
}
