import { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/react';
import { Layout } from '../../components';
import { GridIcon, ListIcon } from '../../assets';
import MugsTable from './MugsTable';
import { useFetchClubs } from '../../hooks';
import { useGetBrewery } from '../../contexts';
import { IClub } from '../../types';

export default function MugsPage(): JSX.Element {
  const [club, setClub] = useState<IClub | undefined>(undefined);
  const [brewery] = useGetBrewery();
  const [clubs, isLoading] = useFetchClubs(brewery?.id);
  const [status, setStatus] = useState<string>('all');
  const [isGridView, setIsGridView] = useState<boolean>(true);

  function handleClubChange(clubId: string): void {
    setClub(clubs.find((club) => club.id === clubId));
  }

  return (
    <Layout
      isLoading={isLoading}
      pageDescription="View the mug inventory of your clubs"
      pageHeading="Mugs"
    >
      <Box
        mb={6}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Box display="flex" gap={4}>
          <FormControl width="300px" flexShrink={0}>
            <FormLabel>Club</FormLabel>
            <Select
              onChange={({ currentTarget }) =>
                handleClubChange(currentTarget.value)
              }
            >
              <option selected disabled>
                Select Club
              </option>
              {clubs
                ?.filter((club) => club.hasMugNumbers)
                .map((club) => (
                  <option key={club.id} value={club.id}>
                    {club.name}
                  </option>
                ))}
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              onChange={({ currentTarget }) => setStatus(currentTarget.value)}
              disabled={!club}
            >
              <option value="all">All</option>
              <option value="available">Available</option>
            </Select>
          </FormControl>
        </Box>

        {club && (
          <ButtonGroup size="sm" isAttached>
            <Button
              leftIcon={<GridIcon />}
              onClick={() => setIsGridView(true)}
              colorScheme="gray"
              bg={isGridView ? 'gray.300' : 'gray.50'}
            >
              Grid
            </Button>
            <Button
              leftIcon={<ListIcon />}
              onClick={() => setIsGridView(false)}
              colorScheme="gray"
              bg={isGridView ? 'gray.50' : 'gray.300'}
            >
              List
            </Button>
          </ButtonGroup>
        )}
      </Box>

      <MugsTable club={club} isGridView={isGridView} status={status} />
    </Layout>
  );
}
