import { Box, Text } from '@chakra-ui/react';
import { Calendar, Repeat } from '../../../assets';
import { ClubType } from '../Context';

interface IProps {
  onClick: (clubType: ClubType) => void;
  selected: boolean;
  type: ClubType;
}

const optionsByType: Record<
  ClubType,
  {
    title: string;
    description: string;
    reasons: string[];
    Icon: React.FC;
  }
> = {
  [ClubType.FIXED]: {
    title: 'Fixed Duration',
    description:
      'Clubs with a fixed duration start and end on specified dates.',
    reasons: [
      'Your club will run from January 1st to December 31st of the same year.',
      'You plan on regularly changing the benefits and/or rewards of your club.',
      'You want to limit the window of time in which new members can signup.',
    ],
    Icon: Calendar,
  },
  [ClubType.SUBSCRIPTION]: {
    title: 'Subscription',
    description:
      'Subscription clubs have rolling memberships that can start at any date and vary in duration, typically from one month to one year.',
    reasons: [
      'Your club will run indefinitely.',
      'New members can join your club at any time throughout the year.',
      'You plan on charging members a recurring subscription fee to join.',
    ],
    Icon: Repeat,
  },
};

export default function StructureOption({
  onClick,
  selected,
  type,
}: IProps): JSX.Element {
  const { title, description, reasons, Icon } = optionsByType[type];

  return (
    <Box>
      <Box
        alignItems="center"
        border="2px solid"
        borderColor={selected ? 'green.100' : 'gray.200'}
        bg={selected ? 'green.50' : ''}
        display="flex"
        gap={4}
        cursor="pointer"
        mb={4}
        p={4}
        rounded="md"
        shadow="md"
        onClick={() => onClick(type)}
      >
        <Box flexShrink={0}>
          <Box
            alignItems="center"
            border="2px solid"
            borderColor={selected ? 'green.200' : 'gray.200'}
            display="flex"
            h={6}
            justifyContent="center"
            w={6}
            rounded="full"
          >
            {selected ? (
              <Box h={3} w={3} bg="green.400" rounded="full" />
            ) : null}
          </Box>
        </Box>

        <Box>
          <Text
            alignItems="center"
            display="flex"
            fontSize="lg"
            fontWeight="semibold"
            gap={2}
            mb={1}
          >
            <Icon />
            {title}
          </Text>
          <Text color="gray.500" fontSize="sm" mb={3}>
            {description}
          </Text>

          <Box fontSize="sm" fontWeight="medium" mb={1}>
            Reasons to choose this option:
          </Box>

          <Box as="ul" ml={4} fontSize="sm">
            {reasons.map((reason: string) => (
              <li key={reason}>{reason}</li>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
